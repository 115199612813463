<template>
  <li>
    <span :level="item.level" @click.stop="handleClick(item.id)">{{ item.label }}</span>
    <ul v-if="item.children.length > 0">
      <RecursiveListItem
        v-for="child in item.children"
        :item="child"
        :key="child.id"
      />
    </ul>
  </li>
</template>
  
  <script>
  const RecursiveListItem = () => import("./RecursiveListItem");
  import  {navigation} from '../../utils/tools.js'
  export default {
    components: {
      RecursiveListItem
    },
    props: {
      item:[]
    },
    data() {
        return{
            visible:true,
            items:[]
        }
    },
    mounted() {
      console.log(this.treeData);
      setTimeout(() => {
          this.visible = false
      }, 1000);
    },
    methods: {
      handleClick(id) {
        navigation(id)
      }
    }
  };
  </script>
  
  <style>

  </style>